import React from 'react'

import * as SEO from 'components/SEO'
import { useLocaleRedirection } from '@arch-log/webapp.lib/react.hook'

/**
 */
const Page = () => {
  useLocaleRedirection('/merit/')

  return <SEO.Default />
}

/**
 */
export default Page
